<template>
  <div class="container-auth">
    <div class="text-left">
      <img
        class="w-100"
        style="max-width: 250px"
        src="@/assets/images/logo.png"
        alt=""
      />
    </div>
    <h3 class="mt-4" style="color: white; font-size: 36px">
      Recover your password to learn
    </h3>
    <h6>Enter your email to send recover link</h6>
    <AlertErrors />
    <div class="form-input mt-4">
      <span>Email</span>
      <b-form-input v-model="form.email" placeholder="carlos@mail.com" />
    </div>
    <!-- <div class="d-flex justify-content-center mt-3">
      <vue-recaptcha
        sitekey="6LdNM6UhAAAAAF1naCwFz1zq7KtOwN1vGCEY1Y1d"
        @verify="verifyCaptcha"
        @expired="expiredCaptcha"
        @error="errorCaptcha"
        theme="dark"
      ></vue-recaptcha>
    </div>
    <div class="text-center mb-2">
      <small>{{ captchaMessage }}</small>
    </div> -->
    <div class="mt-4">
      <ButtonAction
        @click="onSubmit"
        title="Send link"
        :loading="loading"
        block="true"
        lg="true"
        :disabled="form.email == null"
      />
    </div>
    <div class="mt-4">
      <router-link class="btn btn-link w-100" :to="{ name: 'Signin' }">
        SignIn
      </router-link>
    </div>
  </div>
</template>
<script>
import { VueRecaptcha } from "vue-recaptcha";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  components: { VueRecaptcha },
  data() {
    return {
      form: {
        email: null,
      },
      loading: false,
      captchaMessage: null,
      captchaStatus: false,
    };
  },
  methods: {
    ...mapActions("auth", ["resetPassword"]),
    onSubmit() {
      this.loading = true;
      this.resetPassword(this.form).then((response) => {
        this.loading = false;
        openNotification("Check your email to recover password");
        this.$router.push({
          name: "Signin",
        });
      });
      this.loading = false;
    },
    verifyCaptcha() {
      this.captchaStatus = true;
      this.captchaMessage = "Validated correctly";
    },
    expiredCaptcha() {
      this.captchaStatus = false;
      this.captchaMessage = "Please refresh page to load Captcha";
    },
    errorCaptcha() {
      this.captchaStatus = false;
      this.captchaMessage = "Error in Captcha load or solving it";
    },
  },
};
</script>
<style scoped>
.container-auth {
  max-width: 50%;
  margin: auto;
}
h3 {
  text-align: left;
  font-size: 40px;
  line-height: 48px;
  color: white;
}
h6 {
  text-align: left;
  color: white;
  font-size: 22px;
  font-weight: 200;
  line-height: 32px;
}
.form-input {
  text-align: left;
  color: white;
}
</style>
